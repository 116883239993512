(function () {
  'use strict';

  angular
    .module('neo.home.postponements')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('home.postponements', {
        url: '/postponements',
        templateUrl: 'home/postponements/postponements.tpl.html',
        controller: 'PostponementsCtrl',
        controllerAs: 'vm',
        resolve: {
          acl: function ($q, AclService) {
            // TODO: Fix security by url, this is't works because the user is requested after of the verification
            /*if (AclService.can('POSTPONEMENT', 'C')) {
             // Has proper permissions
             return true;
             } else {
             // Does not have permission
             return $q.reject('Unauthorized');
             }*/
          },
          townHalls: function (Ajuntaments) {
            return Ajuntaments.query().$promise;
          },
          currentSeason: function (CurrentSeason) {
            return CurrentSeason.get().$promise;
          }
        }
      });
  }
}());
